<template>

    <div style="width:100%">
        <multiselect v-model="selectedValue"
                     @input="UpdateData"
                     :options="options"
                     :multiple="false"
                     v-bind:disabled="disable"
                     track-by="name"
                     :clear-on-select="false"
                     :preserve-search="false"
                     :show-labels="false"
                     label="name"
                     :placeholder="$t('ProductDropdown.PleaseSelectProduct')">
            <a slot="noResult" class="btn btn-primary " v-on:click="addNewProduct" v-if="(invoiceWoInventory == 'true' || isservice)&&(isValid('CanAddServiceQuickItem') || isValid('CanAddQuickItem'))"> {{ $t('ProductDropdown.AddProduct') }}</a>
            <a slot="noResult" v-else> </a>
        </multiselect>

        <quickProductItem :product="newProduct"
                          :show="showProduct"
                          :addInvoice="addInvoice"
                          v-if="showProduct"
                          @close="showProduct = false"
                          @closeOnSave="onAddNewItem"
                          :type="type" />

        <select-batch :product="selectedProduct"
                      v-if="showBatches"
                      :show="showBatches"
                      @close="showBatches = false"
                      @input="UpdateProduct">

        </select-batch>
    </div>
</template>
<script>
    import Multiselect from "vue-multiselect";
    import { requiredIf, maxLength } from "vuelidate/lib/validators"
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        name: "ProductDropdown",
        props: ["value", "wareHouseId", "dropdownpo", "status", 'productList', "values", "width", 'raw', 'emptyselect', 'serachValue', 'isservice', 'fromSale', 'fromSOrder','fromStockOut', "disable"],

        components: {
            Multiselect,
        },
        data: function () {
            return {
                options: [],
                selectedValue: [],
                productData: [],
                product: {},
                isRaw: false,
                addInvoice: true,
                render: 0,
                arabic: '',
                english: '',
                loading: false,
                showBatches: false,
                invoiceWoInventory: '',
                newProduct: {
                    id: '00000000-0000-0000-0000-000000000000',
                    englishName: '',
                    arabicName: '',
                    salePrice: 0,
                    isActive: true,
                    code: '',
                    taxMethod: '',
                    taxRateId: '',
                    serviceItem: false,

                },
                showProduct: false,
                isFifo: false,
                openBatch: 1,
                selectedProduct: '',
                type: '',
                colorVariants: false,
            };
        },
        validations: {
            product:
            {
                englishName: {
                    maxLength: maxLength(50)
                },
                arabicName: {
                    required: requiredIf((x) => {
                        if (x.englishName == '' || x.englishName == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                }
            }
        },
       
        methods: {
            addNewProduct: function () {
                this.newProduct = {
                    id: '00000000-0000-0000-0000-000000000000',
                    englishName: '',
                    arabicName: '',
                    salePrice: 0,
                    isActive: true,
                    code: '',
                    taxMethod: '',
                    unitId: '',
                    levelOneUnit: '',
                    taxRateId: '',
                    barcode: '',
                    categoryId: '',
                    serviceItem: false,
                }
                this.showProduct = !this.showProduct;
                this.type = "Add";
            },
            //async productListValueCompare(productId) {
            //    return this.productData.find(x => x.id == productId)

            //},
            CallSameFunction: function (productId) {
                this.productListValueCompare(productId);
            },

            productListValueCompare: function (productId) {
                    return this.productData.find(x => x.id == productId)
            },

            productListCheck: function () {
                
                if (this.productData.length == 0) {
                    return false;
                }
                else {
                    return true;

                }

            },
            UpdateData: function () {
                var selectedvalue = this.selectedValue.id;
                if (!this.emptyselect) {
                    this.selectedValue = [];
                }
                if (this.isFifo && (this.fromSale || this.fromSOrder || this.fromStockOut)) {
                    
                    this.selectedProduct = this.productData.find(x => x.id == selectedvalue)
                    this.showBatches = true;
                }
                else {
                     
                    this.$emit('input', selectedvalue, this.productData.find(x => x.id == selectedvalue));
                }

                //this.$emit('input', selectedvalue, this.products.find(x => x.id == selectedvalue));

            },
            UpdateProduct: function (product, batch) {
                this.showBatches = !this.showBatches
                if (this.fromSOrder) {
                    this.$emit('input', product.id, product, null, null, batch);
                }
                else if (this.fromStockOut) {
                    this.$emit('input', product.id, product, batch);
                }
                else {
                    this.$emit('input', product.id, product, null, null, null, null, null, batch);
                }
                

            },
            onAddNewItem: function () {

                this.showProduct = false;
                this.asyncFind();
            },
            asyncFind: function (search) {
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                this.isRaw = this.raw == undefined ? false : this.raw;
                search = search == undefined ? '' : search;
                var service = this.isservice == undefined ? false : true;

                var url = this.wareHouseId != undefined ? "/Product/GetProductInformation?searchTerm=" + search + '&wareHouseId=' + this.wareHouseId + "&isDropdown=true" + '&isRaw=' + root.isRaw + '&isService=' + service + "&isFifo=" + root.isFifo + "&openBatch=" + root.openBatch + "&colorVariants=" + root.colorVariants
                    : "/Product/GetProductInformation?searchTerm=" + search + '&status=' + root.status + "&isDropdown=true" + '&isRaw=' + root.isRaw + "&isFifo=" + root.isFifo + "&openBatch=" + root.openBatch + '&isService=' + service + "&colorVariants=" + root.colorVariants;

                if (search != "" || search != undefined) {
                    this.$https
                        .get(url, {
                            headers: { Authorization: `Bearer ${token}` },
                        })
                        .then(function (response) {
                            //eslint-disable-line
                            if (response.data != null) {
                                root.options = [];
                                root.productData = response.data.results.products;

                                if (root.productList != undefined && root.productList.length > 0) {
                                    root.productList.forEach(function (prod) {
                                        if (root.value == prod.id && root.value != undefined) {
                                            root.selectedValue.push({
                                                id: prod.id,

                                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (prod.englishName != "" && prod.englishName != null) ? prod.englishName : prod.arabicName : (prod.arabicName != '' && prod.arabicName != null) ? prod.arabicName : prod.englishName,
                                            });
                                            root.product = prod;
                                        }

                                    });
                                }

                                if (root.productData != undefined) {
                                    root.productData.forEach(function (prod) {
                                        if (root.value == prod.id && root.value != undefined) {
                                            root.selectedValue.push({
                                                id: prod.id,
                                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (prod.englishName != "" && prod.englishName != null) ? prod.englishName : prod.arabicName : (prod.arabicName != '' && prod.arabicName != null) ? prod.arabicName : prod.englishName,
                                            });
                                            root.product = prod;
                                        }

                                        root.options.push({
                                            id: prod.id,
                                            name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? ((prod.englishName != "" && prod.englishName != null) ? prod.code + ' ' + prod.englishName : prod.code + ' ' + prod.arabicName) + ' ' + (((prod.sizeName != null && prod.sizeName != '') || (prod.sizeNameArabic != null && prod.sizeNameArabic != '')) ? (prod.sizeName != null && prod.sizeName != '') ? prod.sizeName : prod.sizeNameArabic : '') + (((prod.colorName != null && prod.colorName != '') || (prod.colorNameArabic != null && prod.colorNameArabic != '')) ? (prod.colorName != null && prod.colorName != '') ? '--' + prod.colorName : prod.colorNameArabic : '') + ((prod.styleNumber != null && prod.styleNumber != '') ? '--' + prod.styleNumber : '') + ((prod.assortment != null && prod.assortment != '') ? '--' + prod.assortment : '')
                                                : ((prod.arabicName != '' && prod.arabicName != null) ? prod.code + ' ' + prod.arabicName : prod.code + ' ' + prod.englishName) + ' ' + (((prod.sizeNameArabic != null && prod.sizeNameArabic != '') || (prod.sizeName != null && prod.sizeName != '')) ? (prod.sizeNameArabic != null && prod.sizeNameArabic != '') ? prod.sizeNameArabic : prod.sizeName : '') + (((prod.colorNameArabic != null && prod.colorNameArabic != '') || (prod.colorName != null && prod.colorName != '')) ? (prod.colorNameArabic != null && prod.colorNameArabic != '') ? '--' + prod.colorNameArabic : prod.colorName : '') + ((prod.styleNumber != null && prod.styleNumber != '') ? '--' + prod.styleNumber : '') + ((prod.assortment != null && prod.assortment != '') ? '--' + prod.assortment : ''),
                                        });
                                    });
                                }
                            }
                        });
                }
            },
            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.isRaw = this.raw == undefined ? false : this.raw;
                this.$https.get('/Product/GetProductInformation?isActive=true' + "&isDropdown=true" + "&isFifo=" + root.isFifo + "&openBatch=" + root.openBatch, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        response.data.results.productData.forEach(function (prod) {
                            root.selectedValue.push({
                                id: prod.id,
                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (prod.englishName != "" && prod.englishName != null) ? prod.englishName : prod.arabicName : (prod.arabicName != '' && prod.arabicName != null) ? prod.arabicName : prod.englishName,
                            });
                        })
                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        return x.id == root.values;
                    })
                });
            },

        },
        computed: {

        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.colorVariants = localStorage.getItem('ColorVariants') == 'true' ? true : false;
            this.invoiceWoInventory = localStorage.getItem('InvoiceWoInventory');
            this.isFifo = localStorage.getItem('fIFO') == 'true' ? true : false
            var batch = localStorage.getItem('openBatch')
            if (batch != undefined && batch != null && batch != "") {
                this.openBatch = batch
            }
            else {
                this.openBatch = 1
            }

            this.search = this.serachValue;
            if (this.values == undefined || this.values == "") {
                if (this.wareHouseId != "" && this.wareHouseId != undefined) {
                    this.asyncFind(this.value);

                }
                else {
                    this.asyncFind(this.search);
                }
            }
            else {
                this.asyncFind();

            }
        },
    };
</script>
