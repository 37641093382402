<template>
    <div v-if="isValid('CanPrintItemBarcode')" class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title"> {{ $t('MultipleBarcodePrinting.MultipleBarcodePrinting') }}</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('MultipleBarcodePrinting.Home') }}</a></li>
                                    <li class="breadcrumb-item active"> {{ $t('MultipleBarcodePrinting.MultipleBarcodePrinting') }}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card col-md-12 m-auto">
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="tab-content" id="nav-tabContent">
                            <div class="text-left">
                                <div>
                                    <div class="row ">
                                        <!--<div class=" has-label col-xs-4 col-md-4 col-md-4 col-lg-4" >
                                    <label class="text  font-weight-bolder"> {{ $t('MultipleBarcodePrinting.PrintType') }}: </label>
                                    <multiselect v-model="$v.printType.$model"
                                                 :options="['38*28','38*25','50*26']" :multiple="false" :show-labels="false" :clear-on-select="false" :preselect-first="false">

                                    </multiselect>
                                </div>-->
                                        <div class=" form-group col-md-4" >
                                            <label>{{ $t('MultipleBarcodePrinting.FinishingProduct') }} :<span class="text-danger"> *</span></label>
                                            <!--<product-dropdown v-bind:key="render" v-model="productId" v-on:input="GetProduct(productId)" :value="productId" :emptyselect="true" :raw="false" :productList="true"/>-->
                                            <multiselect v-model="selectedValue"
                                                         :options="options"
                                                         :multiple="false"
                                                         v-on:input="GetProduct(selectedValue.id)"
                                                         track-by="name"
                                                         :clear-on-select="false"
                                                         :preserve-search="false"
                                                         :show-labels="false"
                                                         label="name"
                                                         :placeholder="$t('MultipleBarcodePrinting.PleaseSelectProduct')" >
                                                <p slot="noResult"> </p>
                                            </multiselect>
                                        </div>
                                        <div hidden :key="render" class="form-group has-label col-md-4">
                                            <label class="text font-weight-bolder"> {{ $t('MultipleBarcodePrinting.Type') }}: <span class="text-danger"> *</span>  <span class="text-left text-danger">{{barcodes.limit}} Characters</span></label>
                                            <barcodeDropdown :values="barcodes.type" disabled v-model="barcodes.type" ref="getBarcodeLimit" v-on:input="getLimit(barcodes.type)">
                                            </barcodeDropdown>
                                        </div>
                                        <div class="form-group has-label col-md-4">
                                            <label class="text  font-weight-bolder"> {{ $t('MultipleBarcodePrinting.Number') }}: <span class="text-danger"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.barcodes.code.$error}">
                                                <input class="form-control"  v-model="$v.barcodes.code.$model" type="text" />
                                                <span v-if="$v.barcodes.code.$error" class="error text-danger">
                                                    <span v-if="!$v.barcodes.code.required"> {{ $t('MultipleBarcodePrinting.CodeRequired') }}</span>
                                                    <span v-if="!$v.barcodes.code.maxLength">{{ $t('MultipleBarcodePrinting.CodeLimit') }}</span>
                                                </span>
                                                <label v-if="(barcodes.code == '' && message == true)">{{$t('MultipleBarcodePrinting.BarcodeErrorMsg')}}</label>
                                            </div>
                                        </div>

                                        <div class="form-group has-label col-md-4" >
                                            <label class="text  font-weight-bolder"> {{ $t('MultipleBarcodePrinting.Printer') }}: <span class="text-danger"> *</span></label>
                                            <printer-list-dropdown v-model="barcodes.printerName"></printer-list-dropdown>
                                        </div>

                                        <div class="form-group has-label col-md-4" >
                                            <label class="text font-weight-bolder"> {{$t('MultipleBarcodePrinting.salePrice')}}  </label>
                                            <input class="form-control" v-model="barcodes.salePrice" type="text" />
                                        </div>

                                        <div class="form-group has-label col-md-4" >
                                            <label class="text font-weight-bolder"> {{$t('MultipleBarcodePrinting.VATAmount')}}  </label>
                                            <input class="form-control" v-model="barcodes.vatPrice" type="text" />
                                        </div>

                                        <div class="form-group has-label col-md-4" >
                                            <label class="text font-weight-bolder"> {{$t('MultipleBarcodePrinting.TotalwithVAT')}} </label>
                                            <input class="form-control" v-model="barcodes.price" type="text" />
                                        </div>
                                        <div class="form-group has-label col-md-4" >
                                            <label class="text  font-weight-bolder"> {{ $t('MultipleBarcodePrinting.Value') }}: <span class="text-danger"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.barcodes.value.$error}">
                                                <input class="form-control" @focus="$event.target.select()" v-model="$v.barcodes.value.$model" type="number" />
                                                <span v-if="$v.barcodes.value.$error" class="error text-danger">
                                                    <span v-if="!$v.barcodes.value.required"> {{ $t('MultipleBarcodePrinting.ValueRequired') }}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <!--<div class="form-group has-label col-sm-2"    >
                                    <label class="text  font-weight-bolder"> {{ $t('MultipleBarcodePrinting.Limit') }}: <span class="text-danger"> </span></label>
                                    <div v-bind:class="{'has-danger' : $v.barcodes.limitPerLine.$error}">
                                        <input class="form-control" @focus="$event.target.select()" max-length="1" v-model="$v.barcodes.limitPerLine.$model" type="number" />
                                        <span v-if="$v.barcodes.limitPerLine.$error" class="error text-danger">
                                            <span v-if="!$v.barcodes.limitPerLine.between"> {{ $t('MultipleBarcodePrinting.LimitError') }}</span>
                                        </span>
                                    </div>
                                </div>-->
                                        <div class="form-group has-label col-md-4" >
                                            <label for="inlineCheckbox1"> {{ $t('MultipleBarcodePrinting.ShowText') }} : </label>
                                            <div>
                                                <div class="checkbox form-check-inline mx-2"><input type="checkbox"  v-model="barcodes.show" id="inlineCheckbox2"><label for="inlineCheckbox2"></label></div>
                                            </div>
                                        </div>

                                        <!--<label class="pt-4">{{ $t('MultipleBarcodePrinting.ShowItemName') }} :</label> <toggle-button class="pt-4 ml-2" v-model="barcodes.showProdName" color="#3178F6"/>
                                <label class="pt-4">{{ $t('MultipleBarcodePrinting.ShowPrice') }} :</label> <toggle-button class="pt-4 ml-2" v-model="barcodes.showPrice" color="#3178F6"/>-->
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-right">

                                <!--<router-link :to="'/Miscellaneous'">
                            <a href="javascript:void(0)" class="btn btn-outline-primary  "><i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                        </router-link>-->
                                <!--<button v-if="barcodes.code == ''" type="button" class="btn btn-primary  " v-on:click="GenerateBarcode()"> {{ $t('MultipleBarcodePrinting.btnGB') }}</button>-->
                                <button v-bind:disabled="$v.barcodes.$invalid" type="button" class="btn btn-primary  " v-on:click="PrintBarcode()"> {{ $t('MultipleBarcodePrinting.btnPrint') }}</button>

                                <!--<button v-else disabled type="button" class="btn btn-primary  " v-on:click="PrintBarcode()"> {{ $t('MultipleBarcodePrinting.btnPrint') }}</button>-->
                                <button type="button" class="btn btn-outline-danger  mr-3 " v-on:click="close()">{{ $t('MultipleBarcodePrinting.btnClear') }}</button>
                            </div>
                            <div class="row ">
                                <div class="form-group has-label col-sm-12">
                                    <barcode v-if="barcodes.show" :width="1.9" :display-value="true" :height="50" v-bind:value="barcodes.code"></barcode>
                                    <barcode v-else :width="1.9" :display-value="false" :height="50" v-bind:value="barcodes.code"></barcode>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <printMultipleBarcodes :quantity="printList.quantity" :headerFooter="headerFooter" :item="barcodes" :printType="printType" :limit="printList.limitPerLine" :text="printList.isText" :value="printList.value" :type="printList.type" :isShown="printList.isShown" v-bind:key="printRender"></printMultipleBarcodes>
        </div>
        </div>
        <div v-else> <acessdenied></acessdenied></div>
</template>

<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.4/dist/JsBarcode.all.min.js"></script>
<script>

    import clickMixin from '@/Mixins/clickMixin'
    import { required, maxLength, between, minValue } from "vuelidate/lib/validators";
    import 'vue-loading-overlay/dist/vue-loading.css';
    import VueBarcode from 'vue-barcode';
    import Multiselect from 'vue-multiselect'
    export default {
        mixins: [clickMixin],
        components: {
            'barcode': VueBarcode,
            Multiselect,
        },
        data: function () {
            return {
                selectedValue: '',
                render: 0,
                disabled: true,
                printType: '',
                barcodes: {
                    type: '',
                    code: '',
                    value: 1,
                    limit: '',
                    limitPerLine: 1,
                    itemEngName: '',
                    itemArName: '',
                    companyName: '',
                    price: 0,
                    show: true,
                    showPrice: true,
                    showProdName: true,
                    currency: '',
                    printerName: '',
                    vatPrice: 0,
                    salePrice: 0
                },
                barcodeList: [],
                printList: {
                    quantity: 0,
                    value: '',
                    isText: '',
                    type: '',
                    limitPerLine: 1
                },
                printRender: 0,
                productId: '',
                message: false,
                productList: [],
                productName: '',
                headerFooter: {
                    company: '',
                    date: ''
                },
                options: []
            }
        },
        validations: {

            printType: {
                required
            },
            barcodes: {
                code: {
                    required,
                    maxLength: maxLength(25)
                },
                value: {
                    required,
                    minValue: minValue(1)
                },
                limitPerLine: {
                    between: between(1, 3)
                },
                printerName: {
                },
            },

        },
        methods: {

            GetProductList: function () {

                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }


                //search = search == undefined ? '' : search;
                // var url = this.wareHouseId != undefined ? "/Product/GetProductInformation?searchTerm=" + search + '&wareHouseId=' + this.wareHouseId + "&isDropdown=true" + '&isRaw=' + root.isRaw : "/Product/GetProductInformation?searchTerm=" + search + '&status=' + root.status + "&isDropdown=true" + '&isRaw=' + root.isRaw;

                this.$https
                    .get("/Product/GetProductBarcode?isRaw=" + false, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            
                            response.data.results.products.forEach(function (prod) {
                                //taxMethod

                                root.options.push({
                                    id: prod.id,
                                    name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? prod.code + ' ' + ((prod.englishName != '' && prod.englishName != null) ? prod.englishName : prod.arabicName) + ' ' + (((prod.sizeName != null && prod.sizeName != '') || (prod.sizeNameArabic != null && prod.sizeNameArabic != '')) ? (prod.sizeName != null && prod.sizeName != '') ? prod.sizeName : prod.sizeNameArabic : '') + (((prod.colorName != null && prod.colorName != '') || (prod.colorNameArabic != null && prod.colorNameArabic != '')) ? (prod.colorName != null && prod.colorName != '') ? '--' + prod.colorName : prod.colorNameArabic : '') + ((prod.styleNumber != null && prod.styleNumber != '') ? '--' + prod.styleNumber : '') + ((prod.assortment != null && prod.assortment != '') ? '--' + prod.assortment : '')
                                        : prod.code + ' ' + ((prod.arabicName != '' && prod.arabicName != null) ? prod.arabicName : prod.englishName) + ' ' + (((prod.sizeNameArabic != null && prod.sizeNameArabic != '') || (prod.sizeName != null && prod.sizeName != '')) ? (prod.sizeNameArabic != null && prod.sizeNameArabic != '') ? prod.sizeNameArabic : prod.sizeName : '') + (((prod.colorNameArabic != null && prod.colorNameArabic != '') || (prod.colorName != null && prod.colorName != '')) ? (prod.colorNameArabic != null && prod.colorNameArabic != '') ? '--' + prod.colorNameArabic : prod.colorName : '') + ((prod.styleNumber != null && prod.styleNumber != '') ? '--' + prod.styleNumber : '') + ((prod.assortment != null && prod.assortment != '') ? '--' + prod.assortment : ''),
                                    salePrice: prod.salePrice,
                                    taxRate: prod.taxRateValue,
                                    taxMethod: prod.taxMethod
                                });
                            });
                            root.productList = response.data.results.products;

                        }
                    });


            },

            GetProduct: function (id) {

                var data = this.productList.find(function (x) {
                    return x.id == id;
                });
                var root = this;
                if (data != null) {
                    
                    if (data.taxMethod == 'Inclusive' || data.taxMethod == 'شامل') {
                        root.barcodes.vatPrice = parseFloat(((data.salePrice * data.taxRateValue) / (100 + data.taxRateValue)).toFixed(3).slice(0, -1));
                        root.barcodes.price = parseFloat(data.salePrice)
                    }
                    else if (data.taxMethod == 'Exclusive' || data.taxMethod == 'غير شامل') {
                        root.barcodes.vatPrice = ((data.salePrice * data.taxRateValue) / (100)).toFixed(3).slice(0, -1);
                        root.barcodes.price = parseFloat(data.salePrice) + parseFloat(root.barcodes.vatPrice)

                    }
                    else if (data.taxMethod == 'Exempted' || data.taxMethod == 'معفى') {
                        root.barcodes.vatPrice = 0;
                        root.barcodes.price = parseFloat(data.salePrice)
                    }
                    if (data.barCode == '') {
                        root.barcodes.code = '';
                        root.barcodes.value = 1;
                        root.message = true;
                        root.barcodes.itemEngName = data.englishName;
                        root.barcodes.itemArName = data.arabicName;
                        root.barcodes.salePrice = data.salePrice;
                    }
                    else {
                        root.barcodes.code = data.barCode;
                        root.barcodes.itemEngName = data.englishName;
                        root.barcodes.itemArName = data.arabicName;
                        root.barcodes.salePrice = data.salePrice;
                    }
                }

            },
            close: function () {
                this.$router.push('/StartScreen');
            },
            getLimit: function (type, blist) {
                if (type != null) {
                    var newValue = blist.find(function (x) {
                        return x.id == type;
                    })
                    var spanText = newValue.limit;
                    this.barcodes.limit = spanText;
                }
                else {
                    this.barcodes.limit = '';
                }
            },
            PrintBarcode: function () {


                this.printList.quantity = parseInt(this.barcodes.value);
                this.printList.value = this.barcodes.code;
                this.printList.isText = this.barcodes.show;
                this.printList.type = this.barcodes.type;
                this.printList.isShown = true;

                if (this.barcodes.limitPerLine > 0 && this.barcodes.limitPerLine < 4)
                    this.printList.limitPerLine = this.barcodes.limitPerLine
                else {
                    this.printList.limitPerLine = 3
                    this.barcodes.limitPerLine = 3
                }

                this.printRender++;
            },
            GenerateBarcode: function () {


                this.randomNumber = Math.random() * 100; //multiply to generate random number between 0, 100
                this.barcodes.code = this.randomNumber
                this.printRender++;
            },
            GetHeaderDetail: function () {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get("/Company/GetCompanyDetail?id=" + localStorage.getItem('CompanyID'), { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {

                            root.headerFooter.company = response.data;
                        }
                    });
            },
            getBarcodeType: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Company/CompanyAccountSetupDetails', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.barcodes.type = response.data.barcodeType;
                        var btype = root.barcodes.type;
                        root.barcodeList = root.$refs.getBarcodeLimit.options;
                        root.getLimit(btype, root.barcodeList);
                        root.render++;
                    }
                });
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.GetProductList()
            this.getBarcodeType();
            this.barcodes.currency = localStorage.getItem('currency');
            this.GetHeaderDetail();
        },

        //destroyed() {
        //    // Remove listener when component is destroyed
        //    this.$barcodeScanner.destroy()
        //},
    }
</script>
