<template>
    <div>
        <multiselect v-model="DisplayValue"  :options="options" :placeholder="$t('DisplayNameDropdown.DisplayName')" :searchable="false" :multiple="false" track-by="name"  :show-labels="false" label="name" >

        </multiselect>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        name: 'SalutationDropdown',
        props: ["values", 'disabled', 'newCustomer'],

        components: {
            Multiselect
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                options: [],
                value: '',
                loading: false,
            }
        },
        methods: {
            getData: function () {
                var root = this;
                if (this.newCustomer.prefix != '' && this.newCustomer.prefix != null && this.newCustomer.prefix != undefined) {
                    this.options.push({ name: this.newCustomer.prefix +' '+ this.newCustomer.englishName });
                }
                if (this.newCustomer.englishName != '' && this.newCustomer.englishName != null && this.newCustomer.englishName != undefined) {
                    this.options.push({ name: this.newCustomer.englishName });
                }
                if (this.newCustomer.arabicName != '' && this.newCustomer.arabicName != null && this.newCustomer.arabicName != undefined) {
                    this.options.push({ name: this.newCustomer.arabicName });
                }
                if (this.newCustomer.prefix != '' && this.newCustomer.prefix != null && this.newCustomer.prefix != undefined && this.newCustomer.arabicName != '' && this.newCustomer.arabicName != null && this.newCustomer.arabicName != undefined) {
                    this.options.push({ name: this.newCustomer.prefix + ' ' + this.newCustomer.arabicName });
                }
                if (this.newCustomer.companyNameEnglish != '' && this.newCustomer.companyNameEnglish != null && this.newCustomer.companyNameEnglish != undefined) {
                    this.options.push({ name: this.newCustomer.companyNameEnglish });
                }
                if (this.newCustomer.companyNameArabic != '' && this.newCustomer.companyNameArabic != null && this.newCustomer.companyNameArabic != undefined) {
                    this.options.push({ name: this.newCustomer.companyNameArabic });
                }

                if (this.newCustomer.companyNameEnglish != '' && this.newCustomer.companyNameEnglish != null && this.newCustomer.companyNameEnglish != undefined &&this.newCustomer.companyNameArabic != '' && this.newCustomer.companyNameArabic != null && this.newCustomer.companyNameArabic != undefined) {
                    this.options.push({ name: this.newCustomer.companyNameEnglish+'  '+this.newCustomer.companyNameArabic });
                }
                if ((this.newCustomer.englishName != '' && this.newCustomer.englishName != null && this.newCustomer.englishName != undefined) && (this.newCustomer.arabicName != '' && this.newCustomer.arabicName != null && this.newCustomer.arabicName != undefined)) {
                    this.options.push({ name: this.newCustomer.englishName +'  '+this.newCustomer.arabicName });
                }

                
                root.value = root.options.find(function (x) {
                    return x.name == root.values;
                })
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    if (value == null) {
                    this.$emit('input', '');
                }
                    this.value = value;
                    this.$emit('input', value.name);
                }
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.getData();
        },
    }
</script>