<template>
    <div class="row" v-if="isValid('CanViewWarrantyType')">       

<div class="col-lg-12">
    <div class="row">
        <div class="col-sm-12">
            <div class="page-title-box">
                <div class="row">
                    <div class="col">
                        <h4 class="page-title">{{ $t('WarrantyType.WarrantyTypeList') }}</h4>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('WarrantyType.Home') }}</a></li>
                            <li class="breadcrumb-item active">{{ $t('WarrantyType.WarrantyTypeList') }}</li>
                        </ol>
                    </div>
                    <div class="col-auto align-self-center">
                        <a v-if="isValid('CanAddWarrantyType')" v-on:click="openmodel" href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                            <i class="align-self-center icon-xs ti-plus"></i>
                            {{ $t('WarrantyType.AddNew') }}
                        </a>
                        <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);" class="btn btn-sm btn-outline-danger">
                            {{ $t('Categories.Close') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header">
            <div class="input-group">
                <button class="btn btn-secondary" type="button" id="button-addon1"><i class="fas fa-search"></i></button>
                <input v-model="search" type="text" class="form-control" :placeholder="$t('WarrantyType.SearchCodeName')" aria-label="Example text with button addon" aria-describedby="button-addon1">
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table mb-0">
                    <thead class="thead-light table-hover">
                        <tr>
                            <th>#</th>
                                        <th >
                                            {{ $t('WarrantyType.Code') }}
                                        </th>
                                        <th v-if="english=='true'" >
                                            {{ $t('WarrantyType.WarrantyTypeEnglish') }}
                                        </th>
                                        <th v-if="isOtherLang()" >
                                            {{ $t('WarrantyType.WarrantyTypeArabic') }}
                                        </th>
                                        <th class="text-center" width="40%">
                                            {{ $t('WarrantyType.Status') }}
                                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(color,index) in colorlist" v-bind:key="color.id">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>

                                        <td  v-if="isValid('CanEditWarrantyType')">
                                            <strong>
                                                <a href="javascript:void(0)" v-on:click="EditColor(color.id)">  {{color.code}}</a>
                                            </strong>
                                        </td>
                                        <td  v-else>
                                            {{color.code}}

                                        </td>
                                        <td v-if="english=='true'" >
                                            {{color.name}}
                                        </td>
                                        <td v-if="isOtherLang()" >
                                            {{color.nameArabic}}
                                        </td>
                                       
                                        <td class="text-center">
                                        <span v-if="color.isActive" class="badge badge-boxed  badge-outline-success">{{$t('Active')}}</span>
                                        <span v-else class="badge badge-boxed  badge-outline-danger">{{$t('De-Active')}}</span>
                                    </td>
                                    </tr>
                    </tbody>
                </table>
            </div>
            <hr />
            <div class="row">
                <div class="col-lg-6">
                    <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                    <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                    <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                </div>
                <div class=" col-lg-6">
                    <div class=" float-end" v-on:click="GetColorData()">
                        <b-pagination pills size="sm" v-model="currentPage"
                                                    :total-rows="rowCount"
                                                    :per-page="10"
                                                    :first-text="$t('Table.First')"
                                                    :prev-text="$t('Table.Previous')"
                                                    :next-text="$t('Table.Next')"
                                                    :last-text="$t('Table.Last')" >
                                                </b-pagination>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <add-warranty :warrantytype="newWarrantyType"
                    :show="show"
                    v-if="show"
                    @close="IsSave"
                    :type="type" />
</div>

</div>
<div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                arabic: '',
                english: '',
                searchQuery: '',
                show: false,
                colorlist: [],
                newWarrantyType: {
                    id: '',
                    name: '',
                    nameArabic: '',
                    isActive: true
                },
                type: '',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
            }
        },
        watch: {
            search: function (val) {
                this.GetColorData(val, 1);
            }
        },
        methods: {
            IsSave: function () {                
                this.show = false;
                this.GetColorData(this.search, this.currentPage);
            },

            getPage: function () {
                this.GetColorData(this.search, this.currentPage);
            },

            openmodel: function () {
                this.newWarrantyType = {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    nameArabic: '',
                    isActive: true

                }
                this.show = !this.show;
                this.type = "Add";
            },

            GetColorData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Product/WarrantyTypeList?pageNumber=' + this.currentPage + '&searchTerm=' + this.search, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.colorlist = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },

            EditColor: function (Id) {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/WarrantyTypeDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {

                            root.newWarrantyType.id = response.data.id;
                            root.newWarrantyType.name = response.data.name;
                            root.newWarrantyType.nameArabic = response.data.nameArabic;
                            root.newWarrantyType.isActive = response.data.isActive;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetColorData(this.search, 1);
        }
    }
</script>