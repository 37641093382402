<template>
    <div>
        <multiselect v-model="DisplayValue"
                     :options="options"
                     :multiple="false"
                     track-by="name"
                     :clear-on-select="false"
                     :show-labels="false"
                     label="name"
                     :disabled="disable"
                     v-bind:placeholder="$t('PurchaseOrderDropdown.SelectPurchaseOrder')"                  
                     :preselect-first="true">
        </multiselect>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
  //name: "SupplierDropdown",
        props: ["values",'disable','supplierQuotation'],

  components: {
    Multiselect,
  },
  data: function () {
    return {
      options: [],
      value: "",
    };
  },
  methods: {
    getData: function () {
      var root = this;
      var token = "";
      if (this.$session.exists()) {
        token = localStorage.getItem("token");
      }
      var formName = this.supplierQuotation == undefined ? '' : this.supplierQuotation;
      this.$https
          .get('/Purchase/PurchaseOrderList?IsDropDown=' + true+ '&documentType=' + formName , {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then(function (response) {
              
          if (response.data != null) {
            response.data.results.forEach(function (sup) {
              root.options.push({
                id: sup.id,
                  name: sup.registrationNumber,
              });
            });
          }
        })
        .then(function () {
            root.value = root.options.find(function (x) {
            return x.id == root.values;
          });
        });
    },
  },
  computed: {
    DisplayValue: {
      get: function () {
        if (this.value != "" || this.value != undefined) {
          return this.value;
        }
        return this.values;
      },
      set: function (value) {

        if(value != null)
        {
          this.value = value;
          this.$emit("input", value.id);
        }
        else{
          this.value = value;
          this.$emit("input", null);
        }
      },
    },
  },
  mounted: function () {
    this.getData();
  },
};
</script>