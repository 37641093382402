import { render, staticRenderFns } from "./AddSaleService.vue?vue&type=template&id=048dcb82&scoped=true&"
import script from "./AddSaleService.vue?vue&type=script&lang=js&"
export * from "./AddSaleService.vue?vue&type=script&lang=js&"
import style0 from "./AddSaleService.vue?vue&type=style&index=0&id=048dcb82&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "048dcb82",
  null
  
)

export default component.exports