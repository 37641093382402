<template>
    <div class="row" v-if="isValid('CanViewAccountLedger')">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">Advance Invertory Item Summary</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);"> {{
                                        $t('AccountLedger.Home')
                                    }}</a></li>
                                    <li class="breadcrumb-item active">Advance Invertory Item Summary</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <a href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="fas fa-print font-14"></i>
                                    {{ $t('StockReport.Print') }}
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    {{ $t('Categories.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="row align-items-baseline">
                    <div class=" col-lg-4   form-group">
                        <label>Select a Period:</label>
                        <multiselect v-model="reportOpt" :disabled="!isPeriod"
                            :options="['Today', 'This Week', 'This Month', 'This Quarter', 'This Year', 'Yesterday', 'Previous Week', 'Previous Month', 'Previous Quarter', 'Previous Year', 'Custom']"
                            :show-labels="false" v-bind:placeholder="$t('Select an Option')" v-on:input="GetDateTime()">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4   form-group" v-if="showDates">
                        <label>From Date:</label>
                        <datepicker v-model="fromDate" :period="true" :key="rander" />
                    </div>
                    <div class=" col-lg-4   form-group" v-if="showDates">
                        <label>To Date:</label>
                        <datepicker v-model="toDate" :period="true" :key="rander" />
                    </div>

                    <div class=" col-lg-4   form-group">
                        <br />
                        <a v-on:click="AdvanceFilters()" href="javascript:void(0);" class="btn btn-outline-primary me-2">
                            Advance Filters Filters
                        </a>
                    </div>
                </div>
                <div class="row" v-if="advanceFilters">
                    <div class=" col-lg-4   form-group">
                        <label>Report Basis:</label>
                        <multiselect :options="['Accural', 'Cash']" :show-labels="false"
                            v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4   form-group">
                        <label>Filter Accounts:</label>
                        <multiselect
                            :options="['Accounts without zero balance', 'All Accounts', 'Accounts with Transactions']"
                            :show-labels="false" v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4   form-group">
                        <label>Compare:</label>
                        <multiselect :options="['Compare Based on Period/Year', 'Compare Based on Reporting Tags']"
                            :show-labels="false" v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>

                </div>
                <div class="row" v-if="advanceFilters">
                    <div class=" col-lg-4   form-group">
                        <label>Compare With:</label>
                        <multiselect v-model="compareWith"
                            :options="['Previous Year(s)', 'Previous Period(s)', 'Previous Quarter(s)', 'Previous Month(s)']"
                            :show-labels="false" v-bind:placeholder="$t('Select an Option')" v-on:input="GetPeriods()">
                        </multiselect>
                        <div class="mt-1 d-flex align-items-center">
                            <input type="checkbox" class="me-1"><span>Arrange period/year from latest to oldest</span>
                        </div>
                    </div>
                    <div class=" col-lg-4   form-group" v-if="isPreviousYear">
                        <label>Number of Year(s)</label>
                        <multiselect v-model="numberOfPeriods" :options="['1', '2', '3', '4', '5']" :show-labels="false"
                            v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4   form-group" v-if="isPreviousPeriod">
                        <label>Number of Period(s)</label>
                        <multiselect v-model="numberOfPeriods" :options="financialYears" :show-labels="false"
                            v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4   form-group" v-if="isPreviousQuarter">
                        <label>Number of Quarter(s)</label>
                        <multiselect v-model="numberOfPeriods" :options="['1', '2', '3', '4']" :show-labels="false"
                            v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4   form-group" v-if="isPreviousMonth">
                        <label>Number of Month(s)</label>
                        <multiselect v-model="numberOfPeriods"
                            :options="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']" :show-labels="false"
                            v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                </div>
                <div class="row">
                    <div class=" col-lg-4  form-group">
                        <a v-on:click="GetInventoryList()" href="javascript:void(0);" class="btn btn-outline-primary me-2">
                            Apply Filters
                        </a>
                        <a v-on:click="RemoveFilters()" href="javascript:void(0);" class="btn btn-outline-danger">
                            Clear Filters
                        </a>
                    </div>
                </div>
            </div>
            <hr class="hr-dashed hr-menu mt-0" />
            <div class="d-flex bor" v-if="showCompareTable">
                <div class="card col-md-12 border-0" v-if="AdvanceIInventoryItemCompareList1 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList1"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            Product Code
                                        </th>
                                        <th>
                                            Product Name
                                        </th>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact, index) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ contact.productCode }}</td>
                                        <td>
                                            <span v-if="language == 'en'">{{ contact.productName == '' ||
                                                contact.productName == null
                                                ? contact.productArabicName : contact.productName }}</span>
                                            <span v-else>{{ contact.productArabicName == '' ||
                                                contact.productArabicName == null
                                                ? contact.productName : contact.productArabicName }}</span>
                                        </td>
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card col-md-8 border-0" v-if="AdvanceIInventoryItemCompareList2 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList2"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card col-md-8 border-0" v-if="AdvanceIInventoryItemCompareList3 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList3"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card col-md-8 border-0" v-if="AdvanceIInventoryItemCompareList4 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList4"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card col-md-8 border-0" v-if="AdvanceIInventoryItemCompareList5 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList5"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card col-md-8 border-0" v-if="AdvanceIInventoryItemCompareList6 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList6"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card col-md-8 border-0" v-if="AdvanceIInventoryItemCompareList7 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList7"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card col-md-8 border-0" v-if="AdvanceIInventoryItemCompareList8 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList8"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card col-md-8 border-0" v-if="AdvanceIInventoryItemCompareList9 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList9"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card col-md-8 border-0" v-if="AdvanceIInventoryItemCompareList10 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList10"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card col-md-8 border-0" v-if="AdvanceIInventoryItemCompareList11 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList11"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card col-md-8 border-0" v-if="AdvanceIInventoryItemCompareList12 != []">
                    <div class="card-body border-0">
                        <div class="table-responsive" v-for="item in AdvanceIInventoryItemCompareList12"
                            :key="item.compareWithValue">
                            <h6>{{ item.compareWithValue }}</h6>
                            <table class="table table table-striped table-hover table_list_bg">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>
                                            Opening Balance
                                        </th>
                                        <th>
                                            Purchase
                                        </th>
                                        <th>
                                            COGS
                                        </th>
                                        <th>
                                            Sale
                                        </th>
                                        <th>
                                            Inventory
                                        </th>
                                        <th>
                                            Closing Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact) in item.inventoryItems" v-bind:key="contact.productCode">
                                        <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="showTable">
                <div class="card col-md-12">
                    <div class="card-body border-0">
                        <div>
                            <div class="table-responsive">
                                <table class="table table table-striped table-hover table_list_bg">
                                    <thead class="thead-light table-hover">
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                Product Code
                                            </th>
                                            <th>
                                                Product Name
                                            </th>
                                            <th>
                                                Opening Balance
                                            </th>
                                            <th>
                                                Purchase
                                            </th>
                                            <th>
                                                COGS
                                            </th>
                                            <th>
                                                Sale
                                            </th>
                                            <th>
                                                Inventory
                                            </th>
                                            <th>
                                                Closing Balance
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(contact, index) in inventoryItems" v-bind:key="contact.productCode">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ contact.productCode }}</td>
                                            <td>
                                                <span v-if="language == 'en'">{{ contact.productName == '' ||
                                                    contact.productName == null
                                                    ? contact.productArabicName : contact.productName }}</span>
                                                <span v-else>{{ contact.productArabicName == '' ||
                                                    contact.productArabicName == null
                                                    ? contact.productName : contact.productArabicName }}</span>
                                            </td>
                                            <td><span v-if="contact.openingBalance > 0">Dr </span><span
                                                    v-if="contact.openingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.openingBalance) ).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.purchaseBalance > 0">Dr </span><span
                                                    v-if="contact.purchaseBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.purchaseBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.costOfGoodsSoldTotal > 0">Dr </span><span
                                                    v-if="contact.costOfGoodsSoldTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.costOfGoodsSoldTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.saleTotal > 0">Dr </span><span
                                                    v-if="contact.saleTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.saleTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.inventoryTotal > 0">Dr </span><span
                                                    v-if="contact.inventoryTotal < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.inventoryTotal)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                            <td><span v-if="contact.closingBalance > 0">Dr </span><span
                                                    v-if="contact.closingBalance < 0">Cr</span>{{
                                                        Math.abs(parseFloat(contact.closingBalance)).toFixed(3).slice(0,
                                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                "$1,") }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>

    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
</template>
<script>
import clickMixin from '@/Mixins/clickMixin'
import moment from "moment";
import Multiselect from "vue-multiselect";
import Loading from 'vue-loading-overlay';
import "vue-loading-overlay/dist/vue-loading.css";

export default {

    mixins: [clickMixin],
    props: ['formNameProp'],
    components: {
        Multiselect,
        Loading
    },
    data: function () {
        return {
            isPeriod: true,
            showDates: false,
            financialYears: [],
            showComparisonTable: false,
            compareWith: '',
            isPreviousYear: false,
            isPreviousPeriod: false,
            isPreviousQuarter: false,
            isPreviousMonth: false,
            numberOfPeriods: '',
            showTable: false,
            loading: false,
            reportOpt: "",
            dateRender: 0,
            rander: 0,
            advanceFilters: false,

            inventoryItems: [],
            productsList: [],

            showCompareTable: false,
            compareWithValue: '',
            AdvanceIInventoryItemCompareList1: [],
            AdvanceIInventoryItemCompareList2: [],
            AdvanceIInventoryItemCompareList3: [],
            AdvanceIInventoryItemCompareList4: [],
            AdvanceIInventoryItemCompareList5: [],
            AdvanceIInventoryItemCompareList6: [],
            AdvanceIInventoryItemCompareList7: [],
            AdvanceIInventoryItemCompareList8: [],
            AdvanceIInventoryItemCompareList9: [],
            AdvanceIInventoryItemCompareList10: [],
            AdvanceIInventoryItemCompareList11: [],
            AdvanceIInventoryItemCompareList12: [],

        }
    },
    watch: {
    },
    methods: {
        AdvanceFilters: function () {
            this.fromDate = moment().format("DD MMM YYYY");
            this.toDate = moment().format("DD MMM YYYY");
            this.reportOpt = '';
            this.advanceFilters = !this.advanceFilters;
        },
        RemoveFilters: function () {
            this.reportOpt = '';
            this.show = false;
            this.showDates = false;
            this.numberOfPeriods = '';
            this.compareWith = '';
            this.showTable = false;
            this.isPeriod = true;
        },
        convertDate: function (date) {
            return moment(date).format("l");
        },
        GetDateTime: function () {

            if (this.reportOpt == 'Today') {
                this.fromDate = moment().format("DD MMM YYYY");
                this.toDate = moment().format("DD MMM YYYY");
                this.show = false;
                this.showDates = false;
                this.showTable = false;
                this.showComparisonTable = false;
            }
            if (this.reportOpt == 'This Week') {
                // Get the current date
                const currentDate = moment();

                // Get the Monday date of the current week (first day of the week)
                const firstDayOfWeek = moment(currentDate).startOf('week');

                // Format the dates as "DD MMM YYYY"
                this.fromDate = firstDayOfWeek.format("DD MMM YYYY");
                this.toDate = moment().format("DD MMM YYYY");
                this.show = false;
                this.showDates = false;
                this.showTable = false;
                this.showComparisonTable = false;
            }
            if (this.reportOpt == 'This Month') {
                // Get the current date
                const currentDate = moment();

                // Get the first day of the current month
                const firstDayOfMonth = moment(currentDate).startOf('month');

                // Format the dates as "DD MMM YYYY"
                this.fromDate = firstDayOfMonth.format("DD MMM YYYY");
                this.toDate = moment().format("DD MMM YYYY");

                this.show = false;
                this.showDates = false;
                this.showTable = false;
                this.showComparisonTable = false;
            }
            if (this.reportOpt == 'This Quarter') {
                // Get the current date
                const currentDate = moment();

                // Get the first day of the current quarter
                const firstDayOfQuarter = moment(currentDate).startOf('quarter');

                // Format the dates as "DD MMM YYYY"
                this.fromDate = firstDayOfQuarter.format("DD MMM YYYY");
                this.toDate = moment().format("DD MMM YYYY");

                this.show = false;
                this.showDates = false;
                this.showTable = false;
                this.showComparisonTable = false;
            }
            if (this.reportOpt == 'This Year') {
                // Get the current date
                const currentDate = moment();

                // Get the first day of the current year
                const firstDayOfYear = moment(currentDate).startOf('year');

                // Format the dates as "DD MMM YYYY"
                this.fromDate = firstDayOfYear.format("DD MMM YYYY");
                this.toDate = moment().format("DD MMM YYYY");
                this.show = false;
                this.showDates = false;
                this.showTable = false;
                this.showComparisonTable = false;
            }
            if (this.reportOpt == 'Yesterday') {
                // Get the current date
                const currentDate = moment();

                // Get yesterday's date
                const yesterday = moment(currentDate).subtract(1, 'day');

                // Format the dates as "DD MMM YYYY"
                this.fromDate = yesterday.format("DD MMM YYYY");
                this.toDate = moment().format("DD MMM YYYY");
                this.show = false;
                this.showDates = false;
                this.showTable = false;
                this.showComparisonTable = false;
            }
            if (this.reportOpt == 'Previous Week') {
                // Get the current date
                const currentDate = moment();

                // Get the first day (Monday) of the previous week
                const firstDayOfPreviousWeek = moment(currentDate).subtract(1, 'week').startOf('isoWeek');

                // Get the end day (Sunday) of the previous week
                const endDayOfPreviousWeek = moment(currentDate).subtract(1, 'week').endOf('isoWeek');

                // Format the dates as "DD MMM YYYY"
                this.fromDate = firstDayOfPreviousWeek.format("DD MMM YYYY");
                this.toDate = endDayOfPreviousWeek.format("DD MMM YYYY");
                this.show = false;
                this.showDates = false;
                this.showTable = false;
                this.showComparisonTable = false;
            }
            if (this.reportOpt == 'Previous Month') {
                // Get the current date
                const currentDate = moment();

                // Get the first day of the previous month
                const firstDayOfPreviousMonth = moment(currentDate).subtract(1, 'month').startOf('month');

                // Get the end day of the previous month
                const endDayOfPreviousMonth = moment(currentDate).subtract(1, 'month').endOf('month');

                // Format the dates as "DD MMM YYYY"
                this.fromDate = firstDayOfPreviousMonth.format("DD MMM YYYY");
                this.toDate = endDayOfPreviousMonth.format("DD MMM YYYY");
                this.show = false;
                this.showDates = false;
                this.showTable = false;
                this.showComparisonTable = false;
            }
            if (this.reportOpt == 'Previous Quarter') {
                // Get the current date
                const currentDate = moment();

                // Get the first day of the previous quarter
                const firstDayOfPreviousQuarter = moment(currentDate).subtract(1, 'quarter').startOf('quarter');

                // Get the end day of the previous quarter
                const endDayOfPreviousQuarter = moment(currentDate).subtract(1, 'quarter').endOf('quarter');

                // Format the dates as "DD MMM YYYY"
                this.fromDate = firstDayOfPreviousQuarter.format("DD MMM YYYY");
                this.toDate = endDayOfPreviousQuarter.format("DD MMM YYYY");
                this.show = false;
                this.showDates = false;
                this.showTable = false;
                this.showComparisonTable = false;
            }
            if (this.reportOpt == 'Previous Year') {
                // Get the current date
                const currentDate = moment();

                // Get the first day of the previous year
                const firstDayOfPreviousYear = moment(currentDate).subtract(1, 'year').startOf('year');

                // Get the end day of the previous year
                const endDayOfPreviousYear = moment(currentDate).subtract(1, 'year').endOf('year');

                // Format the dates as "DD MMM YYYY"
                this.fromDate = firstDayOfPreviousYear.format("DD MMM YYYY");
                this.toDate = endDayOfPreviousYear.format("DD MMM YYYY");

                this.show = false;
                this.showDates = false;
                this.showTable = false;
                this.showComparisonTable = false;
            }
            if (this.reportOpt == 'Custom') {

                this.toDate = moment().format("DD MMM YYYY");
                const yesterday = moment(this.toDate).subtract(6, 'day');
                // Format the dates as "DD MMM YYYY"
                this.fromDate = yesterday.format("DD MMM YYYY");
                this.show = false;
                this.showDates = true
                this.dateRender++;
                this.showTable = false;
                this.showComparisonTable = false;
            }
        },
        GetPeriods: function () {
            if (this.compareWith == 'Previous Year(s)') {
                this.financialYears = [];
                this.isPreviousYear = true;
                this.isPreviousPeriod = false;
                this.isPreviousQuarter = false;
                this.isPreviousMonth = false;
                this.isPeriod = false;
                this.numberOfPeriods = '';
            }
            if (this.compareWith == 'Previous Period(s)') {
                this.isPreviousYear = false;
                this.isPreviousPeriod = true;
                this.isPreviousQuarter = false;
                this.isPreviousMonth = false;
                this.getFinancialYears();
                this.numberOfPeriods = '';
                this.isPeriod = false;
            }
            if (this.compareWith == 'Previous Quarter(s)') {
                this.financialYears = [];
                this.isPreviousYear = false;
                this.isPreviousPeriod = false;
                this.isPreviousQuarter = true;
                this.isPreviousMonth = false;
                this.numberOfPeriods = '';
                this.isPeriod = false;
            }
            if (this.compareWith == 'Previous Month(s)') {
                this.financialYears = [];
                this.isPreviousYear = false;
                this.isPreviousPeriod = false;
                this.isPreviousQuarter = false;
                this.isPreviousMonth = true;
                this.numberOfPeriods = '';
                this.isPeriod = false;
            }
        },
        GotoPage: function (link) {
            this.$router.push({ path: link });
        },

        GetInventoryList: function () {
            var root = this;
            root.showTable = false;
            root.showCompareTable = false;
            var token = '';
            this.loading = true;
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            //todate = moment(todate).add(1, 'days').format("DD MMM YYYY")
            debugger;
            this.isShown = false;

            this.$https.get('/Report/GetAdvanceInventoryItem?fromDate=' + root.fromDate + '&toDate=' + root.toDate + '&isLedger=true' + '&accountId=' + this.accountId + '&dateType=' + this.dateType + '&numberOfPeriods=' + this.numberOfPeriods + '&compareWith=' + this.compareWith, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        if (root.compareWith != '' && root.compareWith != null) {
                            root.showCompareTable = true;

                            const compareWithList = response.data;
                            console.log(compareWithList);
                            compareWithList.forEach((item, index) => {
                                if (index == 0) {
                                    debugger;
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    console.log(root.inventoryItems);
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList1 = [];
                                    root.AdvanceIInventoryItemCompareList1.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                    root.AdvanceIInventoryItemCompareList2 = [];
                                    root.AdvanceIInventoryItemCompareList3 = [];
                                    root.AdvanceIInventoryItemCompareList4 = [];
                                    root.AdvanceIInventoryItemCompareList5 = [];
                                    root.AdvanceIInventoryItemCompareList6 = [];
                                    root.AdvanceIInventoryItemCompareList7 = [];
                                    root.AdvanceIInventoryItemCompareList8 = [];
                                    root.AdvanceIInventoryItemCompareList9 = [];
                                    root.AdvanceIInventoryItemCompareList10 = [];
                                    root.AdvanceIInventoryItemCompareList11 = [];
                                    root.AdvanceIInventoryItemCompareList12 = [];
                                }
                                if (index == 1) {
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList2 = [];
                                    root.AdvanceIInventoryItemCompareList2.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                    root.AdvanceIInventoryItemCompareList3 = [];
                                    root.AdvanceIInventoryItemCompareList4 = [];
                                    root.AdvanceIInventoryItemCompareList5 = [];
                                    root.AdvanceIInventoryItemCompareList6 = [];
                                    root.AdvanceIInventoryItemCompareList7 = [];
                                    root.AdvanceIInventoryItemCompareList8 = [];
                                    root.AdvanceIInventoryItemCompareList9 = [];
                                    root.AdvanceIInventoryItemCompareList10 = [];
                                    root.AdvanceIInventoryItemCompareList11 = [];
                                    root.AdvanceIInventoryItemCompareList12 = [];
                                }
                                if (index == 2) {
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList3 = [];
                                    root.AdvanceIInventoryItemCompareList3.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                    root.AdvanceIInventoryItemCompareList4 = [];
                                    root.AdvanceIInventoryItemCompareList5 = [];
                                    root.AdvanceIInventoryItemCompareList6 = [];
                                    root.AdvanceIInventoryItemCompareList7 = [];
                                    root.AdvanceIInventoryItemCompareList8 = [];
                                    root.AdvanceIInventoryItemCompareList9 = [];
                                    root.AdvanceIInventoryItemCompareList10 = [];
                                    root.AdvanceIInventoryItemCompareList11 = [];
                                    root.AdvanceIInventoryItemCompareList12 = [];
                                }
                                if (index == 3) {
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList4 = [];
                                    root.AdvanceIInventoryItemCompareList4.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                    root.AdvanceIInventoryItemCompareList5 = [];
                                    root.AdvanceIInventoryItemCompareList6 = [];
                                    root.AdvanceIInventoryItemCompareList7 = [];
                                    root.AdvanceIInventoryItemCompareList8 = [];
                                    root.AdvanceIInventoryItemCompareList9 = [];
                                    root.AdvanceIInventoryItemCompareList10 = [];
                                    root.AdvanceIInventoryItemCompareList11 = [];
                                    root.AdvanceIInventoryItemCompareList12 = [];
                                }
                                if (index == 4) {
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList5 = [];
                                    root.AdvanceIInventoryItemCompareList5.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                    root.AdvanceIInventoryItemCompareList6 = [];
                                    root.AdvanceIInventoryItemCompareList7 = [];
                                    root.AdvanceIInventoryItemCompareList8 = [];
                                    root.AdvanceIInventoryItemCompareList9 = [];
                                    root.AdvanceIInventoryItemCompareList10 = [];
                                    root.AdvanceIInventoryItemCompareList11 = [];
                                    root.AdvanceIInventoryItemCompareList12 = [];
                                }
                                if (index == 5) {
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList6 = [];
                                    root.AdvanceIInventoryItemCompareList6.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                    root.AdvanceIInventoryItemCompareList7 = [];
                                    root.AdvanceIInventoryItemCompareList8 = [];
                                    root.AdvanceIInventoryItemCompareList9 = [];
                                    root.AdvanceIInventoryItemCompareList10 = [];
                                    root.AdvanceIInventoryItemCompareList11 = [];
                                    root.AdvanceIInventoryItemCompareList12 = [];
                                }
                                if (index == 6) {
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList7 = [];
                                    root.AdvanceIInventoryItemCompareList7.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                    root.AdvanceIInventoryItemCompareList8 = [];
                                    root.AdvanceIInventoryItemCompareList9 = [];
                                    root.AdvanceIInventoryItemCompareList10 = [];
                                    root.AdvanceIInventoryItemCompareList11 = [];
                                    root.AdvanceIInventoryItemCompareList12 = [];
                                }
                                if (index == 7) {
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList8 = [];
                                    root.AdvanceIInventoryItemCompareList8.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                    root.AdvanceIInventoryItemCompareList9 = [];
                                    root.AdvanceIInventoryItemCompareList10 = [];
                                    root.AdvanceIInventoryItemCompareList11 = [];
                                    root.AdvanceIInventoryItemCompareList12 = [];
                                }
                                if (index == 8) {
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList9 = [];
                                    root.AdvanceIInventoryItemCompareList9.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                    root.AdvanceIInventoryItemCompareList10 = [];
                                    root.AdvanceIInventoryItemCompareList11 = [];
                                    root.AdvanceIInventoryItemCompareList12 = [];
                                }
                                if (index == 9) {
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList10 = [];
                                    root.AdvanceIInventoryItemCompareList10.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                    root.AdvanceIInventoryItemCompareList11 = [];
                                    root.AdvanceIInventoryItemCompareList12 = [];
                                }
                                if (index == 10) {
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList11 = [];
                                    root.AdvanceIInventoryItemCompareList11.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                    root.AdvanceIInventoryItemCompareList12 = [];
                                }
                                if (index == 11) {
                                    root.inventoryItems = [];
                                    root.inventoryItems = item.compareWithList;
                                    root.productsList.forEach((item) => {
                                        const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                        if (!existingData) {
                                            // If the code doesn't exist in data, create a new object
                                            const newData = {
                                                productCode: item.code,
                                                productName: item.productName,
                                                productArabicName: item.productArabicName,
                                                purchaseBalance: 0,
                                                inventoryTotal: 0,
                                                saleTotal: 0,
                                                costOfGoodsSoldTotal: 0,
                                                openingBalance: 0,
                                                closingBalance: 0,
                                            };
                                            root.inventoryItems.push(newData);
                                        }
                                    });

                                    root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));

                                    root.AdvanceIInventoryItemCompareList12 = [];
                                    root.AdvanceIInventoryItemCompareList12.push({ compareWithValue: item.compareWith, inventoryItems: root.inventoryItems });
                                }
                            });
                        }
                        else {
                            root.showTable = true;
                            root.inventoryItems = response.data;
                            root.productsList.forEach((item) => {
                                const existingData = root.findDataByCode(item.code, root.inventoryItems);
                                if (!existingData) {
                                    // If the code doesn't exist in data, create a new object
                                    const newData = {
                                        productCode: item.code,
                                        productName: item.productName,
                                        productArabicName: item.productArabicName,
                                        purchaseBalance: 0,
                                        inventoryTotal: 0,
                                        saleTotal: 0,
                                        costOfGoodsSoldTotal: 0,
                                        openingBalance: 0,
                                        closingBalance: 0,
                                    };
                                    root.inventoryItems.push(newData);
                                }
                            });

                            root.inventoryItems.sort((a, b) => a.productCode.localeCompare(b.productCode));
                        }
                    }
                    root.loading = false;
                });
        },
        findDataByCode: function (code, list) {
            debugger;
            return list.find((item) => item.productCode === code);
        },
        getFinancialYears: function () {
            var root = this;
            var token = "";
            if (this.$session.exists()) {
                token = localStorage.getItem("token");
            }
            this.$https.get("/Report/GetYearlyPeriodList", { headers: { Authorization: `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        const financialYear = response.data;

                        financialYear.forEach((item) => {
                            root.financialYears.push(item.name);
                        })
                    }
                });
        },
        getProuctsList: function () {
            var root = this;
            var token = "";
            if (this.$session.exists()) {
                token = localStorage.getItem("token");
            }
            this.$https.get('/Product/GetProductInformation?isActive=true' + "&isDropdown=true", { headers: { Authorization: `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        if (response.data != null) {
                            response.data.results.products.forEach(function (prod) {
                                root.productsList.push({
                                    id: prod.id,
                                    code: prod.code,
                                    productName: prod.englishName,
                                    productArabicName: prod.arabicName
                                });
                            })
                        }
                    }
                });
        },
    },
    created: function () {

        this.language = this.$i18n.locale;
        this.fromDate = moment().subtract(15, 'days').format("DD MMM YYYY");
        this.toDate = moment().format("DD MMM YYYY");
        this.getProuctsList();
        this.getFinancialYears();
    }
}
</script>
<style scoped>.bor {
    border: 1px solid #e3ebf6;
    overflow: auto;
}

.table-responsive {
    overflow-x: hidden !important;
}</style>