<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options" :multiple="false" v-bind:placeholder="$t('SaleInvoiceDropdown.Selectinvoice')" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true">
            <p slot="noResult" class="text-danger">{{ $t('SaleInvoiceDropdown.NoInvoiceFound') }}</p>
        </multiselect>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        name: 'ProformaInvoiceDropdown',
        props: ["values", 'isService'],
        mixins: [clickMixin],
        components: {
            Multiselect,
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                options: [],
                value: '',
                show: false,
                type: '',
                render: 0,
                loading: false,
            }
        },
        methods: {
            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                var url = '';
                if (this.isService) {
                    url = '/Sale/SaleList?status=' + 'ProformaInvoice' + '&isDropdown=true' 
                }
                else {
                    url = '/Sale/SaleList?status=' + 'ProformaInvoice' + '&isDropdown=true' 
                }

                this.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    
                    
                    if (response.data != null) {


                        response.data.results.sales.forEach(function (cat) {                            
                            root.options.push({
                                id: cat.id,
                                name: cat.registrationNumber
                            })
                       })
                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        return x.id == root.values;
                    })
                });
            },
            close: function () {
                this.show = false;
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value.id);
                }
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.getData();
        },
    }
</script>