<template>
    <div v-if="isDisabled==true">
        <multiselect v-model="DisplayValue"
                     disabled
                     :options="options"
                     :multiple="false"
                     track-by="name"
                     :clear-on-select="false"
                     :show-labels="false"
                     label="name"
                     v-bind:placeholder="$t('SaleOrderDropdown.SelectOption')"                   
                     :preselect-first="true">
        </multiselect>
    </div>
    <div v-else>
        <multiselect v-model="DisplayValue"
                     
                     :options="options"
                     :multiple="false"
                     track-by="name"
                     :clear-on-select="false"
                     :show-labels="false"
                     label="name"
                     v-bind:placeholder="$t('SaleOrderDropdown.SelectOption')"                   
                     :preselect-first="true">
        </multiselect>
    </div>
</template>
<script>
    import Multiselect from "vue-multiselect";
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        mixins: [clickMixin],

        //name: "SupplierDropdown",
        props: ["values", 'isservice','isDisabled'],

        components: {
            Multiselect,
        },
        data: function () {
            return {
                options: [],
                value: "",
            };
        },
        methods: {
            getData: function () {
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                var url = '';
                if (this.isservice) {
                    url = '/Purchase/SaleServiceOrderList?isDropdown=true'
                }
                else {
                    url = '/Purchase/SaleOrderList?isDropdown=true'
                }


                this.$https.get(url, {headers: { Authorization: `Bearer ${token}` },})
                    .then(function (response) {

                        if (response.data != null) {
                            response.data.results.forEach(function (sup) {
                                root.options.push({
                                    id: sup.id,
                                    name: sup.registrationNumber,
                                });
                            });
                        }
                    })
                    .then(function () {
                        root.value = root.options.find(function (x) {
                            return x.id == root.values;
                        });
                    });
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {

                    if (value != null) {
                        this.value = value;
                        this.$emit("input", value.id);
                    }
                    else {
                        this.value = value;
                        this.$emit("input", null);
                    }
                },
            },
        },
        mounted: function () {
            this.getData();
        },
    };
</script>